/* eslint-disable import/no-cycle */
import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';
import ProspectTagsRow from '../../../../../../../shared/design-system/components/molecules/prospect-tags-row';
import { Column } from '../../../../../../../shared/design-system/components/organisms/table/types';
import Avatar from '../../../../../../../shared/design-system/components/atoms/initials-avatar/avatar';
import ProspectSequenceName from './prospect-sequence-name';
import { ProspectSortBy } from '../../../../../types';
import { Order } from '../../../../../../templates/enums/templates.enum';
import TruncatedTextWithTooltip from '../../../../../../../shared/components/truncated-text-with-tooltip';
import OutcomeCell from './outcome-cell';
import { OverlayTooltip } from '../../../../../../../shared/design-system/components/overlay';

export const getProspectColumn = (
  action: (id: number) => void,
  onSort: (field: ProspectSortBy, order: Order) => void,
): Column[] => [
  {
    dataField: 'fields',
    text: 'Prospects Name',
    headerAlign: 'left',
    cellClasses: 'name-field',
    component: 'contact-name-field',
    headerLoadingSkeleton: <SkeletonLoading width={120} height={14} />,
    cellLoadingSkeleton: (
      <div>
        <SkeletonLoading width={72} height={14} />
        <SkeletonLoading width={160} height={8} />
      </div>
    ),
  },
  {
    dataField: 'tags',
    text: 'Tags',
    headerAlign: 'left',
    cellClasses: 'tag-detail',
    style: () => ({ width: '5rem' }),
    component: (cell) => <ProspectTagsRow tags={cell} />,
    headerLoadingSkeleton: <SkeletonLoading width={29} height={14} />,
    cellLoadingSkeleton: (
      <div className="d-flex">
        <SkeletonLoading width={84} height={24} />
        <div className="ml-2">
          <SkeletonLoading width={64} height={24} />
        </div>
      </div>
    ),
  },
  {
    dataField: 'sequences',
    text: 'Sequence',
    headerAlign: 'left',
    cellClasses: 'sequence-detail',
    component: ({ sequences, allOutcomes }) => (
      <ProspectSequenceName sequences={sequences} allOutcomes={allOutcomes} />
    ),
    headerLoadingSkeleton: <SkeletonLoading width={83} height={14} />,
    cellLoadingSkeleton: <SkeletonLoading width={164} height={14} />,
  },
  {
    dataField: 'outcomes',
    text: 'Outcome',
    headerAlign: 'left',
    component: ({ sequencesDetails, prospectId, allOutcomes }) => (
      <OutcomeCell
        sequences={sequencesDetails}
        prospectId={prospectId}
        allOutcomes={allOutcomes}
      />
    ),
    headerLoadingSkeleton: <SkeletonLoading width={83} height={14} />,
    cellLoadingSkeleton: <SkeletonLoading width={164} height={14} />,
  },
  {
    dataField: 'lastActivity',
    text: 'Last Activity',
    headerAlign: 'left',
    align: 'left',
    cellClasses: 'last-activity',
    component: (_cell, row) => {
      const cellValue = row.sequences;
      const latestActivity = cellValue[0]?.lastActivity;
      return (
        <span className="regular-2 popover-arrow-color-txt">
          {latestActivity || '-'}
        </span>
      );
    },
    headerLoadingSkeleton: <SkeletonLoading width={95} height={14} />,
    cellLoadingSkeleton: <SkeletonLoading width={85} height={14} />,
  },
  {
    dataField: 'created',
    text: 'Owner',
    headerAlign: 'left',
    align: 'left',
    sort: true,
    onSort: (_field, order) => onSort(ProspectSortBy.Owner, order),
    component: (cell) => {
      const { firstName, lastName } = cell || { firstName: '', lastName: '' };
      return (
        <OverlayTooltip text={`${firstName} ${lastName}`}>
          <div className="owner-wrap">
            <Avatar
              firstName={firstName}
              lastName={lastName}
              className="sequence-avatar pointer"
            />
          </div>
        </OverlayTooltip>
      );
    },
    headerLoadingSkeleton: <SkeletonLoading width={90} height={14} />,
    cellLoadingSkeleton: (
      <div className="d-flex align-items-center">
        <SkeletonLoading width={24} height={24} circle />
        <div style={{ marginLeft: '10px' }}>
          <SkeletonLoading width={80} height={14} />
        </div>
      </div>
    ),
  },
];

export const getModalColumn: Column[] = [
  {
    dataField: 'name',
    text: 'Name',
    headerAlign: 'left',
    component: (cell) => <TruncatedTextWithTooltip string={cell} length={30} />,
    headerStyle: {
      borderTop: '1px solid #dee2e6',
    },
    headerLoadingSkeleton: <SkeletonLoading width={95} height={14} />,
    cellLoadingSkeleton: <SkeletonLoading width={85} height={14} />,
  },
  {
    dataField: 'email',
    text: 'Email',
    headerAlign: 'left',
    component: (cell) => <span>{cell}</span>,
    headerStyle: {
      borderTop: '1px solid #dee2e6',
    },
    headerLoadingSkeleton: <SkeletonLoading width={95} height={14} />,
    cellLoadingSkeleton: <SkeletonLoading width={85} height={14} />,
  },
];
