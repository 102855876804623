/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ArrowUpRight } from '@saleshandy/icons';
import { SkeletonLoading } from '@saleshandy/design-system';
import { useTranslation } from 'react-i18next';
import { isValueBasedPlan } from '../../../../../../components/settings/components/billing-subscription/components/outreach-plans/utils/helper';
import { SubscriptionPlans } from '../../../../../utils/subscription-plans';
import { getCoreFeaturesCreditsRequest } from '../../../../../../components/home/extra-actions';
import { constants } from '../../../../../enums/constants';
import { executeOnRequestStatus } from '../../../../../utils';
import { useCreditsSelectors } from './credits-box-helper';
import { resetCoreFeaturesCreditsRequestStatus } from '../../../../../../components/home/home-slice';

function CreditsBox( {dropdownIsOpen} ) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [hoveredCredit, setHoveredCredit] = useState<string | null>(null);
  const [loading, setLoading] = useState(true); // Loading state to control skeleton visibility

  const {
    planCode,
    planType,
    leadFinderCreditsAvailable,
    emailSendAvailable,
    emailSendTotal,
    prospectAddAvailable,
    prospectAddTotal,
    emailVerificationAvailable,
    getCoreFeaturesCreditsRequestStatus,
  } = useCreditsSelectors();


  useEffect(() => {
    executeOnRequestStatus({
      status: getCoreFeaturesCreditsRequestStatus,
      onSuccess: () => {
        setLoading(false);
      },
      onFailed: () => {
        setLoading(false);
      },
      onPending: () => {
        setLoading(true);
      },
    });
  }, [getCoreFeaturesCreditsRequestStatus ,dropdownIsOpen]);
  useEffect(() => {
    if (dropdownIsOpen) {
      // Reset the status to 'ideal' to allow re-fetching
      dispatch(resetCoreFeaturesCreditsRequestStatus());

      // Now dispatch the request to fetch data
      dispatch(getCoreFeaturesCreditsRequest());
    }
  }, [dropdownIsOpen, dispatch]);

  const creditsData = [
    {
      title: t('labels.lead_finder_credits'),
      available: parseInt(leadFinderCreditsAvailable, 10) || 0,
      path: '/settings/billing/subscriptions/lead-finder',
    },
    {
      title: t('labels.email_verification'),
      available: parseInt(emailVerificationAvailable, 10) || 0,
      path: '/settings/billing/subscriptions/ev-credits',
    },
  ];

  const additionalCreditsData = [
    {
      title: t('labels.monthly_emails_sent'),
      available: parseInt(emailSendAvailable, 10) || 0,
      total: parseInt(emailSendTotal, 10) || 0,
    },
    {
      title: t('labels.total_prospects'),
      available: parseInt(prospectAddAvailable, 10) || 0,
      total: parseInt(prospectAddTotal, 10) || 0,
    },
  ];

  const calculateRemainingPercentage = (
    available: number,
    total: number,
  ): number => {
    if (!total || total <= 0) return 0; // Avoid division by zero or negative totals
    return Math.round((available / total) * 100);
  };

  const getRemainingColor = (available, title) => {
    if (
      (title === t('labels.lead_finder_credits') &&
        available < constants.LEAD_REVEAL_BOTTOM_LIMIT) ||
      (title === t('labels.email_verification') &&
        available < constants.EMAIL_VERIFICATION_BOTTOM_LIMIT)
    ) {
      return 'credit-count-red';
    }
    return 'gray-txt-12';
  };

  const handleMouseEnter = (title) => {
    setHoveredCredit(title);
  };

  const handleMouseLeave = () => {
    setHoveredCredit(null);
  };

  const isBelowThreshold = (available: number, title: string): boolean => {
    const leadFinderThreshold = constants.LEAD_REVEAL_BOTTOM_LIMIT;
    const emailVerificationThreshold =
      constants.EMAIL_VERIFICATION_BOTTOM_LIMIT;

    return (
      (title === t('labels.lead_finder_credits') &&
        available < leadFinderThreshold) ||
      (title === t('labels.email_verification') &&
        available < emailVerificationThreshold)
    );
  };

  return (
    <div className="credits-box">
      {creditsData.map((credit) => (
        <div
          className="credit-item"
          key={credit.title}
          onMouseEnter={() => handleMouseEnter(credit.title)}
          onMouseLeave={handleMouseLeave}
        >
          <div className="credit-header">
            <span className="credit-title">
              {credit.title}
            </span>
            <span
              className={`credit-count ${getRemainingColor(
                credit.available,
                credit.title,
              )}`}
            >
              {loading ? (
                <SkeletonLoading height={12} width={104} />
              ) : hoveredCredit === credit.title &&
                isBelowThreshold(credit.available, credit.title) ? (
                <a href={credit.path} className="text-blue-500">
                  Add Credits <ArrowUpRight />
                </a>
              ) : (
                credit.available.toLocaleString()
              )}
            </span>
          </div>
        </div>
      ))}

      {/* Conditional Divider and Additional Credits */}
      {(isValueBasedPlan(planType) ||
        planCode === SubscriptionPlans.TRIAL ||
        planCode === SubscriptionPlans.FREE) && (
        <>
          <div className="divider-dropdown" />

          {additionalCreditsData.map((credit) => {
            const creditsUsedPercentage =
              100 -
              calculateRemainingPercentage(credit.available, credit.total);
            return (
              <div
                className="credit-item "
                key={credit.title + creditsData.length}
                onMouseEnter={() => handleMouseEnter(credit.title)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="credit-header">
                  <span className="credit-title">
                    {credit.title}
                  </span>
                  <span className="credit-count  gray-txt-12">
                    {loading ? (
                      <SkeletonLoading height={12} width={104} />
                    ) : hoveredCredit === credit.title &&
                      creditsUsedPercentage >= 85 ? (
                      <a
                        href="/settings/billing/subscriptions/email-outreach"
                        className="credit-link"
                      >
                        Upgrade <ArrowUpRight />
                      </a>
                    ) : 
                    (
                      <>
                        <span className="credit-used">
                          {(credit.total - credit.available).toLocaleString()}
                        </span>
                        {/* {" / "} */} /
                        <span className="credit-total">
                          {credit.total.toLocaleString()}
                        </span>
                      </>
                    )
                    }
                  </span>
                </div>
                {loading ? (
                  <SkeletonLoading width={272} height={4} />
                ) : (
                  <div className="credit-bar">
                    <div
                      className={`credit-bar-progress ${
                        creditsUsedPercentage >= 85 ? 'warning' : ''
                      }`}
                      style={{ width: `${creditsUsedPercentage}%` }}
                     />
                  </div>
                )}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default CreditsBox;
