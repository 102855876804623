import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { InfoCircle } from '@saleshandy/icons';
import Button from '../../../../../../../shared/design-system/components/atoms/button';
import Icon from '../../../../../../../shared/design-system/components/atoms/icon/icon';
import ProspectsFilter from '../../../../../../../shared/design-system/components/molecules/prospects-filter';
import { prospectsFiltersInitialState } from '../../../../../prospect-slice';
import {
  Filters,
  ProspectsFilters,
} from '../../../../../../../shared/types/prospects-filters';
import type { IProps } from './prospects-filters-modal-container';
import { FilterType } from '../../../../../../../shared/design-system/components/molecules/prospects-filter/type';
import {
  dateToString,
  getFormattedDate,
} from '../../../../../../../shared/functions/date';
import { ContactTotal } from '../../../../../../sequence/enums';
import {
  statuses,
  verificationStatuses,
  creationSource,
  taskPriorities,
} from '../../../../../helpers/prospect-filters-resolver';
import { constants } from '../../../../../../../shared/enums/constants';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';
import MultiSelect from '../../../../../../settings/components/users-and-teams/utils/multi-select';
import {
  isAgency,
  isAgencyUser,
} from '../../../../../../../shared/utils/user-details';
import { getClientName } from '../../../../../../agency-client-management/helpers/helpers';
import RadioButtonGroup from '../../../../../../../shared/design-system/components/radio-button-group';
import { OverlayTooltip } from '../../../../../../../shared/design-system/components/overlay/tooltip/overlay-tooltip';
import { Placement } from '../../../../../../../shared/design-system/components/overlay';
import { ConversationType } from '../../../../../enums/prospect';
import { getConversationTypeLabel } from '../../../utils/helper';

const defaultFiltersToDisplay: Record<Filters, boolean> = {
  conversationType: true,
  tags: true,
  owners: true,
  sequences: true,
  status: true,
  outcomes: true,
  'verification status': true,
  'task priority': true,
  'created date': true,
  'creation source': true,
  'current step': true,
  'email account': true,
  'client associated': true,
};

/**
 * Renders all the prospects filters.
 */
const ProspectsFiltersModal: React.FC<IProps> = ({
  show,
  hide,
  prospectsFilters,
  isUniboxFilters = false,
  getProspects,
  getProspectsCount,
  setProspectsFilters,
  resetProspectsFilters,
  tags,
  sendGetSequenceList,
  sequences,
  owners,
  teamsAndUsersList,
  sendGetOwnersList,
  sendGetTeamsAndMembersListRequest,
  except = [],
  selectedStatus,
  sendGetTagsRequest,
  paginationOptions,
  handleClearSelection,
  changeProspectPagination,
  allOutcomes,
  isATMOwnerList,
  isTasksFilter,
  handleClearAll,
  sendGetAllOutcomesRequest,
  stepsDetails,
  sendGetStepDetailsListRequest,
  sequenceId,
  unifiedInboxContext,
  emails,
  sendGetEmailAccounts,
  clients,
  sendGetAgencyClientsDropdownRequest,
  sendGetSequencesListForFilterRequest,
  uniboxSequences,
  uniboxCategory,
}) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<ProspectsFilters>(prospectsFilters);
  const [conversationType, setConversationType] = useState<ConversationType>(
    () => {
      if (prospectsFilters?.conversationType?.length > 0) {
        if (
          prospectsFilters?.conversationType?.[0]?.name ===
          ConversationType.External
        ) {
          return ConversationType.External;
        }
        if (
          prospectsFilters?.conversationType?.[0]?.name ===
          ConversationType.System
        ) {
          return ConversationType.System;
        }
      }
      return ConversationType.All;
    },
  );
  const filtersToDisplay = useMemo(
    () =>
      except.reduce(
        (acc, f) => ({ ...acc, [f]: false }),
        defaultFiltersToDisplay,
      ),
    [except],
  );

  useEffect(() => {
    if (!isUniboxFilters && filtersToDisplay.sequences) {
      sendGetSequenceList();
    }

    if (filtersToDisplay['current step'] && sequenceId) {
      sendGetStepDetailsListRequest(sequenceId);
    }

    // Getting all the members of the user's SH Account
    if (
      (hasPermission(Permissions.ACCOUNT_USER_READ) ||
        hasPermission(Permissions.TEAM_USER_READ)) &&
      !isATMOwnerList
    ) {
      sendGetTeamsAndMembersListRequest();
    }

    // Get Owners List
    if (
      ((hasPermission(Permissions.ACCOUNT_USER_READ) ||
        hasPermission(Permissions.TEAM_USER_READ)) &&
        isATMOwnerList) ||
      isAgencyUser()
    ) {
      sendGetOwnersList();
    }

    // Get All Outcomes
    if (
      hasPermission(Permissions.UNIBOX_CATEGORY_UPDATE) &&
      allOutcomes.length === 0 &&
      !isUniboxFilters
    ) {
      sendGetAllOutcomesRequest();
    }

    // Get Email Accounts
    if (isUniboxFilters) {
      sendGetEmailAccounts();
      sendGetSequencesListForFilterRequest();
    }

    // Get Clients
    if (hasPermission(Permissions.CLIENT_READ) && isAgency()) {
      sendGetAgencyClientsDropdownRequest({});
    }

    // Get Tags
    if (!isUniboxFilters) {
      sendGetTagsRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resolveAndSetFilters = (name: Filters, value: any[]) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleDoneUnifiedInbox = () => {
    const appliedFilters = {
      ...filters,
      conversationType: [{ name: conversationType }],
    };
    if (selectedStatus && selectedStatus !== ContactTotal.TOTAL) {
      const selectedStatusFromProspectHeader = statuses
        ?.filter((item) => !item.isHeaderFilter)
        ?.find((item) => item.id === selectedStatus);

      const isAppliedFilterAlreadyExist = appliedFilters?.status?.some(
        (item) => item.id === selectedStatus,
      );

      if (!isAppliedFilterAlreadyExist) {
        appliedFilters.status = [
          ...appliedFilters.status,
          selectedStatusFromProspectHeader || {
            id: selectedStatus,
            name: selectedStatus,
            isHeaderFilter: true,
          },
        ];
      }
    }

    const { 'created date': createdDate } = appliedFilters;
    if (createdDate.filter((dateObj) => dateObj.date).length !== 2) {
      appliedFilters['created date'] = [];
      setFilters(appliedFilters);
    }

    setProspectsFilters(appliedFilters);
    getProspects?.(appliedFilters);
    changeProspectPagination({
      ...paginationOptions,
      pageNum: constants.DEFAULT_PAGE_NUM,
    });
    getProspectsCount?.({});
    hide();

    handleClearSelection && handleClearSelection();
  };

  const handleDone = () => {
    if (unifiedInboxContext) {
      handleDoneUnifiedInbox();
      return;
    }

    const appliedFilters = { ...filters };

    if (selectedStatus && selectedStatus !== ContactTotal.TOTAL) {
      const selectedStatusFromProspectHeader = statuses
        ?.filter((item) => !item.isHeaderFilter)
        ?.find((item) => item.id === selectedStatus);

      const isAppliedFilterAlreadyExist = appliedFilters?.status?.some(
        (item) => item.id === selectedStatus,
      );

      if (!isAppliedFilterAlreadyExist) {
        appliedFilters.status = [
          ...appliedFilters.status,
          selectedStatusFromProspectHeader || {
            id: selectedStatus,
            name: selectedStatus,
            isHeaderFilter: true,
          },
        ];
      }
    }

    /**
     * Handling a case where only startDate is selected.
     */
    const { 'created date': createdDate } = appliedFilters;
    if (createdDate.filter((dateObj) => dateObj.date).length !== 2) {
      appliedFilters['created date'] = [];
      setFilters(appliedFilters);
    }

    setProspectsFilters(appliedFilters);
    getProspects?.({
      pageNum: constants.DEFAULT_PAGE_NUM,
      pageSize: paginationOptions?.pageSize || constants.DEFAULT_PAGE_SIZE,
    });
    changeProspectPagination({
      ...paginationOptions,
      pageNum: constants.DEFAULT_PAGE_NUM,
    });
    getProspectsCount?.({});
    hide();

    handleClearSelection && handleClearSelection();
  };

  const handleCancel = () => {
    setFilters({ ...prospectsFilters });
    hide();
  };

  const handleResetUnifiedInbox = () => {
    setFilters(prospectsFiltersInitialState);
    resetProspectsFilters('all');

    if (handleClearAll) {
      handleClearAll();
    } else {
      const appliedFilters = { ...prospectsFiltersInitialState };
      getProspects?.(appliedFilters);
      changeProspectPagination({
        ...paginationOptions,
        pageNum: constants.DEFAULT_PAGE_NUM,
      });
    }

    hide();
  };

  const handleReset = () => {
    if (unifiedInboxContext) {
      handleResetUnifiedInbox();
      return;
    }

    setFilters(prospectsFiltersInitialState);
    resetProspectsFilters('all');

    if (handleClearAll) {
      handleClearAll();
    } else {
      getProspects?.({
        pageNum: constants.DEFAULT_PAGE_NUM,
        pageSize: paginationOptions.pageSize || constants.DEFAULT_PAGE_SIZE,
      });
      getProspectsCount({});
      changeProspectPagination({
        ...paginationOptions,
        pageNum: constants.DEFAULT_PAGE_NUM,
      });
    }

    hide();
  };

  const setTagFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.Tags, f);
  };

  const setStatusFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.Status, f);
  };

  const setOutcomeFilters = (selectedItems: any[]) => {
    if (unifiedInboxContext) {
      const selectedOutcome = selectedItems.slice(-1);
      resolveAndSetFilters(Filters.Outcomes, selectedOutcome);
    } else {
      resolveAndSetFilters(Filters.Outcomes, selectedItems);
    }
  };

  const setCurrentStepFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.CurrentStep, f);
  };

  const setVerificationStatusFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.VerificationStatus, f);
  };

  const setTaskPriorityFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.TaskPriority, f);
  };

  const setCreationSourceFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.CreationSource, f);
  };

  const setSequenceFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.Sequences, f);
  };

  const setEmailAccountFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.EmailAccount, f);
  };

  const setClientFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.ClientAssociated, f);
  };

  const setOwnersFilters = (f: any[]) => {
    const filteredValue = f.map((item) => ({
      firstName: item?.label || item?.firstName,
      id: item.id,
    }));
    resolveAndSetFilters(Filters.Owners, filteredValue);
  };

  const getAllOutcomes = (_outcomes) => {
    if (isUniboxFilters) {
      return uniboxCategory?.map((category) => ({
        name: category.name,
        id: category.id,
      }));
    }

    return _outcomes?.map((outcome) => ({
      name: outcome.name,
      id: outcome.id,
    }));
  };

  const setDateFilter = (f: any[]) => {
    const startDate = f[0];
    const endDate = f[1];

    resolveAndSetFilters(Filters.CreatedDate, [
      {
        date: f[0] && dateToString(startDate),
        displayDate: f[0] && getFormattedDate(startDate),
      },
      {
        date: f[1] && dateToString(endDate),
        displayDate: f[1] && getFormattedDate(endDate),
      },
    ]);
  };

  const sequenceOptions = () => {
    if (isUniboxFilters) {
      return uniboxSequences;
    }

    return isTasksFilter
      ? sequences
      : [
          {
            id: null,
            title: 'None (Not part of any sequence)',
            isNoneSequence: true,
          },
          ...sequences,
        ];
  };
  const emailAccountOptions = emails.length
    ? emails.map((email) => ({
        email: email?.fromEmail,
        id: email?.id,
      }))
    : [];

  const clientsOptions = clients.length
    ? clients.map((client) => ({
        email: getClientName(client, 60, {
          company: true,
          email: true,
          deleted: true,
        }),
        id: client?.id,
      }))
    : [];

  const mappedOwners = useMemo(
    () =>
      owners?.length
        ? owners.map((item) => ({
            ...item,
            firstName:
              `${item.firstName}  ${item.lastName}`.trim() || item.email,
            value: item.id,
          }))
        : [],
    [owners],
  );

  const mappedStepsDetails = useMemo(
    () =>
      stepsDetails?.length
        ? stepsDetails.map((item) => ({
            id: item.id,
            name: `Step ${item.number}`,
          }))
        : [],
    [stepsDetails],
  );

  const renderRadioButton = (type: ConversationType) => {
    const labelClassNames = classNames({
      'enabled-radio': conversationType === type,
      'disabled-radio': conversationType !== type,
    });

    return (
      <div className="radio-input-btn">
        <h2 className={labelClassNames}>{getConversationTypeLabel(type)}</h2>
      </div>
    );
  };

  const onShowConversationChange = (selectedValue: ConversationType) => {
    setConversationType(selectedValue);
    if (selectedValue === ConversationType.External) {
      setFilters({
        ...filters,
        'client associated': [],
        owners: [],
        sequences: [],
        outcomes: [],
      });
    }
  };

  const isFilterDisabled = (filterName: string) =>
    conversationType === ConversationType.External &&
    filterName !== 'Client Associated' &&
    filterName !== 'Date' &&
    filterName !== 'Email Accounts';

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      className="prospects-filters-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          <span className="semibold-3">{t('labels.filters')}</span>
        </Modal.Title>
        <Button className="close" onClick={hide}>
          <Icon identifier="close" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {filtersToDisplay.conversationType && (
          <div className="radio-input-wrapper">
            <div className="show-conversation-title semibold-1 gray-txt-15">
              Show Conversation
              <OverlayTooltip
                placement={Placement.RightStart}
                className="tooltip-xl"
                text="External displays conversations not started by Sequences, but where recipients from the same domain or the prospect reply directly to your connected email account."
              >
                <InfoCircle width={14} height={14} className="ml-1" />
              </OverlayTooltip>
            </div>
            <div className="radio-input-container">
              <RadioButtonGroup
                name="conversationType"
                value={conversationType}
                options={[
                  {
                    label: renderRadioButton(ConversationType.All),
                    value: ConversationType.All,
                    disabled: false,
                  },
                  {
                    label: renderRadioButton(ConversationType.External),
                    value: ConversationType.External,
                    disabled: false,
                  },
                  {
                    label: renderRadioButton(ConversationType.System),
                    value: ConversationType.System,
                    disabled: false,
                  },
                ]}
                onChange={onShowConversationChange}
              />
            </div>
          </div>
        )}

        {hasPermission(Permissions.CLIENT_READ) &&
          isAgency() &&
          filtersToDisplay['client associated'] && (
            <ProspectsFilter
              label="Client Associated"
              containerClassName={`bs-mb-20 ${
                isFilterDisabled('Client Associated') ? 'disabled-filter' : ''
              }`}
              onSelect={setClientFilters}
              options={clientsOptions}
              labelKey="email"
              placeholder="Search and Select Client Associated"
              values={[...filters['client associated']]}
              isDisabled={isFilterDisabled('Client Associated')}
            />
          )}

        {filtersToDisplay.owners &&
          filtersToDisplay.sequences &&
          !isAgencyUser() &&
          (hasPermission(Permissions.ACCOUNT_USER_READ) ||
            hasPermission(Permissions.TEAM_USER_READ)) && (
            <div className="prospects-filter-container bs-mb-20">
              <div
                className={`semibold-1 gray-txt-15 ${
                  isFilterDisabled('Sequence Owner') ? 'disabled-filter' : ''
                }`}
              >
                {unifiedInboxContext ? 'Sequence Owner' : 'Prospect Owner'}
              </div>
              <MultiSelect
                placeholder="Search and Select Owners"
                options={teamsAndUsersList}
                values={[...filters.owners]}
                onSelect={setOwnersFilters}
                labelKey="label"
                isDisabled={isFilterDisabled(
                  unifiedInboxContext ? 'Sequence Owner' : 'Prospect Owner',
                )}
                containerClassName={`${
                  isFilterDisabled(
                    unifiedInboxContext ? 'Sequence Owner' : 'Prospect Owner',
                  )
                    ? 'disabled-filter'
                    : ''
                }`}
              />
            </div>
          )}
        {filtersToDisplay.sequences && (
          <ProspectsFilter
            label={t('labels.sequence')}
            onSelect={setSequenceFilters}
            options={sequenceOptions()}
            labelKey="title"
            placeholder="Search and Select Sequence"
            values={[...filters.sequences]}
            isDisabled={isFilterDisabled('Sequence')}
            containerClassName={`${
              isFilterDisabled('Sequence') ? 'disabled-filter' : ''
            }`}
          />
        )}
        {filtersToDisplay['email account'] && (
          <ProspectsFilter
            label="Email Accounts"
            containerClassName={`bs-mt-20 ${
              isFilterDisabled('Email Accounts') ? 'disabled-filter' : ''
            }`}
            onSelect={setEmailAccountFilters}
            options={emailAccountOptions}
            labelKey="email"
            placeholder="Search and Select Email Account"
            values={[...filters['email account']]}
            isDisabled={isFilterDisabled('Email Accounts')}
          />
        )}
        {filtersToDisplay.status && (
          <ProspectsFilter
            label={t('labels.status')}
            containerClassName="bs-mt-10"
            onSelect={setStatusFilters}
            options={statuses.filter((item) => !item.isHeaderFilter)}
            placeholder="Search and Select Status"
            values={
              filters.status?.filter((item) => !item.isHeaderFilter) || []
            }
          />
        )}
        {filtersToDisplay.outcomes && (
          <ProspectsFilter
            label={unifiedInboxContext ? 'All Outcomes' : t('labels.outcomes')}
            containerClassName={`bs-mt-20 ${
              isFilterDisabled('All Outcomes') ? 'disabled-filter' : ''
            }`}
            onSelect={setOutcomeFilters}
            options={getAllOutcomes(allOutcomes)}
            placeholder="Select Outcomes"
            isMultiSelect={!unifiedInboxContext}
            values={[...(filters?.outcomes || [])]}
            isDisabled={isFilterDisabled('All Outcomes')}
          />
        )}
        {filtersToDisplay['current step'] && (
          <ProspectsFilter
            label={t('labels.current_step')}
            containerClassName="bs-mt-20"
            onSelect={setCurrentStepFilters}
            options={mappedStepsDetails}
            placeholder="Select Step"
            values={[...(filters?.['current step'] || [])]}
          />
        )}
        {filtersToDisplay.tags && (
          <ProspectsFilter
            label={t('labels.tags')}
            containerClassName="bs-mt-20"
            onSelect={setTagFilters}
            options={tags}
            placeholder="Search and Select Tags"
            values={[...filters.tags]}
          />
        )}

        {filtersToDisplay['verification status'] && (
          <ProspectsFilter
            label={t('labels.verification_status')}
            containerClassName="bs-mt-20"
            onSelect={setVerificationStatusFilters}
            options={verificationStatuses}
            placeholder="Search and Select Status"
            values={[...filters['verification status']]}
          />
        )}

        {filtersToDisplay['task priority'] && (
          <ProspectsFilter
            label="Task Priority"
            containerClassName="bs-mt-20"
            onSelect={setTaskPriorityFilters}
            options={taskPriorities}
            placeholder="Search and Select Priority"
            values={[...(filters['task priority'] || [])]}
          />
        )}

        {filtersToDisplay['creation source'] && (
          <ProspectsFilter
            label={t('labels.creation_source')}
            containerClassName="bs-mt-20"
            onSelect={setCreationSourceFilters}
            options={creationSource}
            placeholder="Creation Source"
            values={[...(filters?.['creation source'] || [])]}
            placement="auto"
          />
        )}

        {(isATMOwnerList || isAgencyUser()) &&
          (hasPermission(Permissions.ACCOUNT_USER_READ) ||
            hasPermission(Permissions.TEAM_USER_READ)) && (
            <ProspectsFilter
              label={t('labels.owners')}
              containerClassName="bs-mt-20"
              onSelect={setOwnersFilters}
              options={mappedOwners}
              labelKey="firstName"
              placeholder="Search and Select Owners"
              values={[...filters.owners]}
            />
          )}

        {filtersToDisplay['created date'] && (
          <ProspectsFilter
            label={unifiedInboxContext ? 'Date' : t('labels.created_date')}
            containerClassName={`bs-mt-20 ${
              isFilterDisabled('Date') ? 'disabled-filter' : ''
            }`}
            onSelect={setDateFilter}
            options={[]}
            placeholder="Search and Select Sequence"
            values={[...filters['created date']]}
            filterType={FilterType.Date}
            isDisabled={false}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-row align-self-start flex-grow-1">
          <Button
            variant={Button.Variant.Light}
            theme={Button.Theme.Ghost}
            onClick={handleReset}
            className="rst-btn"
          >
            {t('labels.clear_all')}
          </Button>
          <div className="d-flex flex-row justify-content-end flex-grow-1">
            <Button
              variant={Button.Variant.Outlined}
              theme={Button.Theme.Ghost}
              onClick={handleCancel}
            >
              {t('labels.cancel')}
            </Button>
            <Button
              variant={Button.Variant.Primary}
              onClick={handleDone}
              className="ml-4"
            >
              {t('labels.apply')}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(ProspectsFiltersModal);
