import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button } from '@saleshandy/design-system';
import { Images } from '../../../../../../../../shared/app-constants';
import ShButton from '../../../../../../../../shared/design-system/components/atoms/button';
import { RequestStatus } from '../../../../../../../../shared/enums/request-status';
import { showGeneralErrorNotification } from '../../../../../../../../shared/utils/errors';
import ProspectListSearchBar from '../../../../../../../prospect/components/prospect-list/components/prospect-actions/components/prospect-list-search-bar';
import ProspectListTable from '../../../../../../../prospect/components/prospect-list/components/prospect-list-table';
import { getModalColumn } from '../../../../../../../prospect/components/prospect-list/components/prospect-list-table/helpers/prospect-column-data';
import AddToStepSelect from '../../../../../../shared/add-to-step-select';
import EmptyList from '../../../../../../../../shared/design-system/components/molecules/empty-list/empty-list';
import Modal from '../../../../../../../../shared/design-system/components/atoms/modal';
import type { IProps } from './search-and-add-modal-container';
import AddProspectForm from '../../../../../../../../shared/components/add-prospect-form';
import { constants } from '../../../../../../../../shared/enums/constants';
import { getIsRequestPending } from '../../../../../../helpers/helper';

const showHideLoadingBar = (
  status,
  showLoading,
  hideLoading,
  error,
  showGeneralErrorNotificationFunc,
) => {
  if (status === RequestStatus.Pending) {
    showLoading();
  }
  if (status === RequestStatus.Succeeded) {
    hideLoading();
  }
  if (status === RequestStatus.Failed) {
    hideLoading();
    if (error) {
      showGeneralErrorNotificationFunc(error.message);
    }
  }
};

const getMappedProspects = (prospects) =>
  prospects.map(({ id, attributes, firstName, lastName, email }) => {
    let firstNameValue = '';
    let lastNameValue = '';
    let emailValue = '';

    attributes?.forEach((item) => {
      if (item.fieldRef?.label === 'First Name') {
        firstNameValue = item.attributeValue;
      }
      if (item.fieldRef?.label === 'Last Name') {
        lastNameValue = item.attributeValue;
      }
      if (item.fieldRef?.label === 'Email') {
        emailValue = item.attributeValue;
      }
    });

    return {
      id,
      name: `${firstNameValue || firstName || ''} ${
        lastNameValue || lastName || ''
      }`.trim(),
      email: emailValue || email || '',
    };
  });

const getSelectedProspects = (selectedProspects, rows, isSelect) => {
  let prospects;
  if (isSelect) {
    prospects = selectedProspects.concat(rows);
    return prospects;
  }
  prospects = selectedProspects.reduce((acc, item) => {
    let isPresent = false;
    rows.forEach(({ id }) => {
      if (id === item.id) {
        isPresent = true;
      }
    });
    if (isPresent) {
      return acc;
    }
    return [...acc, item];
  }, []);
  return prospects;
};
const SearchAndAddModal: React.FC<IProps> = ({
  getProspectRequestError,
  getProspectRequestStatus,
  prospects,
  sendGetProspectRequest,
  prospectCount,
  hideLoading,
  showLoading,
  resetProspect,
  sendGetProspectCountRequest,
  closeModal,
  showSearchAndAddModal,
  stepList,
  addToStepRequestError,
  addToStepRequestFailedCount,
  addToStepRequestStatus,
  addToStepRequestSuccessfulCount,
  addToStepRequestResultData,
  remainingProspectLimit,
  sendAddContactToStepRequest,
  onSubmit,
  showRestrictionErrorModal,
  sendGetSequenceStepsRequest,
}) => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState<string | null>(null);
  const [paginationData, setPaginationData] = useState({
    pageNum: constants.DEFAULT_PAGE_NUM,
    pageSize: constants.DEFAULT_PAGE_SIZE,
  });
  const [selectedProspects, setSelectedProspects] = useState([]);
  const [selectedSteps, setSelectedStep] = useState<number>(null);
  const [isAddProspectView, setIsAddProspectView] = useState<boolean>(false);
  const { sequenceId } = useParams<{ sequenceId: string }>();

  useEffect(() => {
    sendGetProspectRequest({
      pageNum: constants.DEFAULT_PAGE_NUM,
      pageSize: constants.DEFAULT_PAGE_SIZE,
    });
    sendGetProspectCountRequest({});
    sendGetSequenceStepsRequest(Number(sequenceId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showHideLoadingBar(
      getProspectRequestStatus,
      showLoading,
      hideLoading,
      getProspectRequestError,
      showGeneralErrorNotification,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProspectRequestStatus]);

  useEffect(() => {
    showHideLoadingBar(
      addToStepRequestStatus,
      showLoading,
      hideLoading,
      addToStepRequestError,
      showGeneralErrorNotification,
    );
    if (addToStepRequestStatus === RequestStatus.Succeeded) {
      // If the request is succeed send the data like successCount and failedCount
      // to the parent using callback and tell to close this modal and open the modal for report
      onSubmit({
        successful: addToStepRequestSuccessfulCount,
        failed: addToStepRequestFailedCount,
        resultData: addToStepRequestResultData,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToStepRequestStatus]);

  useEffect(
    () => () => {
      resetProspect();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const stepOptions = stepList.map((step) => ({
      ...step,
      key: step.id,
    }));
    if (stepOptions.length > 0) {
      setSelectedStep(
        stepOptions.reduce(
          (previousStep, currentStep) =>
            previousStep.number < currentStep.number
              ? previousStep
              : currentStep,
          stepOptions[0],
        ).key,
      );
    }
  }, [stepList]);

  // After user clicks the submit button send an API to the backend for adding the
  // selected contact to the list and also open the report for successful count and failed

  const stepOptions = stepList.map((step) => ({
    ...step,
    key: step.id,
  }));

  const changeProspectPagination = (paginationOptions) => {
    setPaginationData(paginationOptions);
  };

  const selectAllContact = (rows, isSelect: boolean) => {
    setSelectedProspects(
      getSelectedProspects(selectedProspects, rows, isSelect),
    );
  };

  const selectSingleContact = (row, isSelect) => {
    if (isSelect) {
      setSelectedProspects(selectedProspects.concat([row]));
      return;
    }
    setSelectedProspects(
      selectedProspects.filter((prospect) => prospect.id !== row.id),
    );
  };

  const addToSequenceStep = () => {
    sendAddContactToStepRequest({
      contactIds: selectedProspects.map((item) => item.id),
      sequenceId,
      stepId: stepOptions.find((step) => step.key === selectedSteps).id,
    });
  };

  const handleAddProspectModal = () => {
    if (remainingProspectLimit === 0) {
      showRestrictionErrorModal();
      return;
    }

    setIsAddProspectView(!isAddProspectView);
  };

  const data = getMappedProspects(prospects);

  const isRequestPending = getIsRequestPending(getProspectRequestStatus);

  const renderEmptyList = () => {
    const isSearchStringEmpty = searchString === null;
    return (
      <div className="empty-prospect text-center d-flex justify-content-center align-items-center h-100">
        <EmptyList
          description={
            isSearchStringEmpty
              ? t('messages.import_prospect_and_start_engaging')
              : t('messages.create_new_prospect_description')
          }
          title={
            isSearchStringEmpty
              ? t('labels.empty_prospects')
              : t('labels.no_results_found')
          }
          imgSrc={Images.EmptyData1}
          isVertical={true}
        >
          <Button onClick={handleAddProspectModal}>
            {t('labels.create_new_prospect')}
          </Button>
        </EmptyList>
      </div>
    );
  };

  const renderProspectListTable = () => (
    <Col className="h-100">
      <ProspectListTable
        getProspectRequestStatus={getProspectRequestStatus}
        changePagination={changeProspectPagination}
        getProspectRequest={sendGetProspectRequest}
        prospectCount={prospectCount}
        prospectPaginationData={paginationData}
        search={searchString}
        selectAllContact={selectAllContact}
        selectSingleContact={selectSingleContact}
        resetSelected={false}
        column={getModalColumn}
        data={data}
        showNewPagination={false}
        isLoading={isRequestPending}
      />
    </Col>
  );

  return (
    <Modal
      show={showSearchAndAddModal}
      className="import-contacts-modal search-and-add modal-custom-footer"
      titleContent={
        isAddProspectView ? 'Create & Add Prospect' : 'Search & Add Prospect(s)'
      }
      showCloseIcon={true}
      onClose={closeModal}
      onSubmit={addToSequenceStep}
      isSubmitDisabled={
        selectedProspects.length === 0 ||
        addToStepRequestStatus === RequestStatus.Pending
      }
      isSubmitLoading={addToStepRequestStatus === RequestStatus.Pending}
      isCancelDisabled={addToStepRequestStatus === RequestStatus.Pending}
      submitButtonText="Add to Sequence"
      submitButtonClassName="header-btn"
      cancelButtonTheme={ShButton.Theme.Solid}
      cancelButtonVarient={ShButton.Variant.Default}
      hideFooter={isAddProspectView}
      backdrop="static"
      footerContent={
        <AddToStepSelect
          setStepKey={setSelectedStep}
          stepKey={selectedSteps}
          stepOptions={stepOptions}
        />
      }
      extraModalProps={{
        centered: true,
        'aria-labelledby': 'contained-modal-title-vcenter',
      }}
    >
      {!isAddProspectView ? (
        <>
          <Row className="d-flex justify-content-between">
            <Col sm={5} className="pt-3 pb-3">
              <ProspectListSearchBar
                sendGetProspectRequest={sendGetProspectRequest}
                sendGetProspectCountRequest={sendGetProspectCountRequest}
                saveSearch={setSearchString}
                changePagination={changeProspectPagination}
                searchValue={searchString}
              />
            </Col>
            {prospects.length > 0 && (
              <div className="mt-3 mr-4">
                <Button variant="secondary" onClick={handleAddProspectModal}>
                  {t('labels.create_new_prospect')}
                </Button>
              </div>
            )}
          </Row>
          {!isRequestPending && prospects.length === 0 && renderEmptyList()}
          <Row className="overflow-hidden">
            {isRequestPending && renderProspectListTable()}
            {!isRequestPending &&
              prospects.length !== 0 &&
              renderProspectListTable()}
          </Row>
        </>
      ) : (
        <AddProspectForm
          containerClasses="p-1"
          buttonTitle={t('labels.create_and_add_to_sequence')}
          sequenceId={sequenceId}
          stepId={stepOptions?.find((step) => step.key === selectedSteps)?.id}
          onClose={handleAddProspectModal}
          shouldSendAddToContactStepRequest={true}
          content={
            <AddToStepSelect
              setStepKey={setSelectedStep}
              stepKey={selectedSteps}
              stepOptions={stepOptions}
            />
          }
        />
      )}
    </Modal>
  );
};

export default SearchAndAddModal;
