import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import { EmailServiceProvider } from '../../enums/email-account';
import { showPlanErrorForSubscription } from '../email-account/components/update-email-account/helpers';
import { EspLimits } from './types';

export const zeroLimitChecker = (
  sendingLimit: number,
  isBulkUpdatingEmailAccounts: boolean,
  maxLimit: number,
): boolean =>
  isBulkUpdatingEmailAccounts
    ? Number(sendingLimit) < 0 ||
      (Number(sendingLimit) > 4000 && sendingLimit > maxLimit)
    : Number(sendingLimit) < 0;

export const softLimitChecker = (
  sendingLimit: number,
  softLimit: number,
  hardLimit: number,
  softLimitAllowed: boolean,
) => sendingLimit > softLimit && sendingLimit < hardLimit && !softLimitAllowed;

export const hardLimitChecker = (
  sendingLimit: number,
  hardLimit: number,
  maxLimit: number,
): boolean => sendingLimit > hardLimit && sendingLimit > maxLimit;

export const lowerLimitChecker = (
  sendingLimit: number,
  rampUpStatus: boolean,
  rampUpLimit: number,
): boolean => rampUpStatus && sendingLimit < rampUpLimit;

export const saveButtonDisabledChecker = (
  zeroLimitError: boolean,
  hardLimitError: boolean,
  lowerLimitError: boolean,
  maxLimitReached: boolean,
  isUpdateMaxSendingLimitLoading: boolean,
): boolean => {
  return (
    maxLimitReached ||
    zeroLimitError ||
    hardLimitError ||
    lowerLimitError ||
    isUpdateMaxSendingLimitLoading
  );
};

export const shouldAllowLimitUpdateChecker = (
  zeroLimitError: boolean,
  softLimitError: boolean,
  hardLimitError: boolean,
  lowerLimitError: boolean,
  softLimitAllowed: boolean,
  maxLimitReached:boolean,
  isUpdateMaxSendingLimitLoading: boolean,
): boolean =>
  saveButtonDisabledChecker(
    zeroLimitError,
    hardLimitError,
    lowerLimitError,
    maxLimitReached,
    isUpdateMaxSendingLimitLoading,
  ) ||
  (softLimitError && !softLimitAllowed);

export const getSettingsLimitForEmailAccount = (
  emailAccountSettings,
): {
  maxSendingLimit: number;
  rampUpStatus: boolean;
  rampUpLimit: number;
  minInterval: number;
  maxInterval: number;
} => {
  const settingsForEmailAccount = {
    maxSendingLimit: 0,
    rampUpStatus: false,
    rampUpLimit: 0,
    minInterval: 0,
    maxInterval: 0,
  };

  if (emailAccountSettings.length) {
    emailAccountSettings.forEach((setting) => {
      if (setting.code === 'max-sending-limit') {
        settingsForEmailAccount.maxSendingLimit = parseInt(setting.value, 10);
      }
      if (setting.code === 'ramp-up-status') {
        settingsForEmailAccount.rampUpStatus = Boolean(Number(setting.value));
      }
      if (setting.code === 'ramp-up-limit') {
        settingsForEmailAccount.rampUpLimit = parseInt(setting.value, 10);
      }
      if (setting.code === 'min-interval') {
        settingsForEmailAccount.minInterval = parseInt(setting.value, 10);
      }
      if (setting.code === 'max-interval') {
        settingsForEmailAccount.maxInterval = parseInt(setting.value, 10);
      }
    });
  }

  return settingsForEmailAccount;
};

export const getLimitsForEmailAccount = ({
  emailAccount,
  espLimits,
}): {
  hardLimit: number;
  softLimit: number;
} => {
  let espLimit: EspLimits | Record<string, string> = {};
  let hardLimit: number;
  let softLimit: number;

  if (emailAccount) {
    espLimit = espLimits.find(
      (esp) => emailAccount.emailServiceProvider === esp.esp,
    );

    hardLimit = parseInt(espLimit?.hardLimit, 10);
    softLimit = parseInt(espLimit?.softLimit, 10);
  }
  return {
    hardLimit,
    softLimit,
  };
};

export const executeBasedOnSubscriptionPlan = (
  subscriptionPlan: SubscriptionPlans,
  execute: () => void,
) => {
  if (subscriptionPlan === SubscriptionPlans.FREE) {
    showPlanErrorForSubscription();
  } else {
    execute();
  }
};

export const getEmailServiceProvider = (emailAccount) =>
  emailAccount ? emailAccount.emailServiceProvider : '';

export const getESPSoftLimitWarningContentOne = (
  emailServiceProvider: EmailServiceProvider,
  softLimit,
  t,
) => {
  let warningMessage;

  switch (emailServiceProvider) {
    case EmailServiceProvider.Gmail:
    case EmailServiceProvider.Microsoft:
      warningMessage = `${t(
        'messages.soft_limit_modal_content_1',
      )} ${softLimit} ${t('messages.soft_limit_modal_content_2')}
      ${t('messages.soft_limit_modal_content_3')}
      ${
        emailServiceProvider === EmailServiceProvider.Microsoft
          ? 'OUTLOOK'
          : emailServiceProvider.toUpperCase()
      } ${t('messages.soft_limit_modal_content_4')} ${t(
        'messages.soft_limit_modal_content_5',
      )}`;
      break;

    default:
      warningMessage = `${t(
        'messages.soft_limit_modal_content_1',
      )} ${softLimit} ${t('messages.soft_limit_modal_content_9')}
    ${t('messages.soft_limit_modal_content_8')}.`;
  }

  return warningMessage;
};
