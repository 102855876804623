import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SkeletonLoading } from '@saleshandy/design-system';
import {
  Adjustments,
  Filter,
  Refresh,
  Trash,
  Trulyinbox,
  Unlink,
} from '@saleshandy/icons';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import type { IProps } from './email-accounts-actions-container';

import SearchInput from '../../../../../../shared/design-system/components/atoms/search-input';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import {
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import { IconPlace } from '../../../../../../shared/design-system/components/input/enums';
import ConfirmationModalV3 from '../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../../../../../shared/design-system/components/atoms/confirmation-modal/enum';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import { isAgency } from '../../../../../../shared/utils/user-details';
import AddRemoveTag from '../../../../../../shared/components/add-remove-tag';
import { RootState } from '../../../../../../store/root-reducer';
import {
  emailAccountTagsAssignRequest,
  emailAccountTagsBulkAssignRequest,
  emailAccountTagsBulkUnassignRequest,
  emailAccountTagsUnassignRequest,
  getEmailAccountsRequest,
} from '../../extra-actions';
import toaster, { Theme } from '../../../../../../shared/toaster';
import EmailAccountsFiltersModal from '../email-accounts-filters-modal';
import { resetSelectedEmailAccount } from '../../email-account-slice';
import { EmailAccountFilterStatus } from '../../../../types/email-account';

const EmailAccountsActions: React.FC<IProps> = ({
  onAddEmailAccount,
  openBulkEmailUpdateModal,
  planCode,
  selectedEmailAccounts,
  bulkSelectedEmailAccounts,
  getConnectedUsersAndEmailAccountsRequestStatus,
  sendBulkEmailAccountUpdateRequest,
  bulkEmailUpdateRequestStatus,
  sendGetAgencyClientsDropdownRequest,
  getAgencyClientsDropdownRequestStatus,
  syncConsentRequestResponse,
  syncConsentRequestStatus,
  onLoginWithTrulyInbox,
  onTrulyInboxEmailSync,
  emailAccountsFilters,
  emailAccountTagsList,
  resetEmailAccountTagsList,
  onFiltersChange,
  sendGetEmailAccountsTagsRequest,
  getEmailAccountsRequestStatus,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [search, setSearch] = useState(emailAccountsFilters.search || '');
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const addTagsRequestStatus = useSelector(
    (state: RootState) =>
      state.emailAccount.emailAccountTagsAssignRequest.status,
  );

  const removeTagsRequestStatus = useSelector(
    (state: RootState) =>
      state.emailAccount.emailAccountTagsUnassignRequest.status,
  );

  const bulkAddTagsRequestStatus = useSelector(
    (state: RootState) =>
      state.emailAccount.emailAccountTagsBulkAssignRequest.status,
  );

  const bulkRemoveTagsRequestStatus = useSelector(
    (state: RootState) =>
      state.emailAccount.emailAccountTagsBulkUnassignRequest.status,
  );

  // const [clientIds, setClientIds] = useState(filters.clientIds || []);

  const openEmailAccountsFiltersModal = () => setIsFiltersModalOpen(true);
  const closeEmailAccountsFiltersModal = () => setIsFiltersModalOpen(false);

  const [
    showDisconnectEmailConfirmationPopup,
    setShowDisconnectEmailConfirmationPopup,
  ] = useState(false);
  const [
    showDeleteEmailConfirmationPopup,
    setShowDeleteEmailConfirmationPopup,
  ] = useState(false);

  const onSearch = () => {
    onFiltersChange({ search, page: 1 });
  };

  const onClearSearch = () => {
    setSearch('');
    onFiltersChange({ search: '', page: 1 });
  };

  const onHideBulkEmailDisconnectConfirmationModal = () => {
    setShowDisconnectEmailConfirmationPopup(false);
  };

  const onHideBulkEmailDeleteConfirmationModal = () => {
    setShowDeleteEmailConfirmationPopup(false);
  };

  // Initialize refs outside the array
  const bulkUpdateSettingsRef = useRef(null);
  const refreshSetupScoreRef = useRef(null);
  const disconnectEmailAccountsRef = useRef(null);
  const deleteEmailAccountsRef = useRef(null);
  const addRemoveTagRef = useRef(null);

  const actions = [
    (hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_GENERAL_SETTING_UPDATE) ||
      hasPermission(
        Permissions.EMAIL_ACCOUNT_SETTINGS_SENDING_SETTING_UPDATE,
      )) && {
      key: 'bulk_update_settings',
      tooltip: 'Bulk update Settings',
      ref: bulkUpdateSettingsRef,
      icon: <Adjustments height={24} width={24} />,
      onClick: () => {
        openBulkEmailUpdateModal();
      },
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_SETUP_SCORE) && {
      key: 'refresh_setup_score',
      tooltip: 'Refresh Setup Score ',
      ref: refreshSetupScoreRef,
      icon: <Refresh height={24} width={24} />,
      onClick: () => {
        sendBulkEmailAccountUpdateRequest({
          refreshSetupScore: true,
          ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
            ? {
                allEmailsSelected: true,
                ignoreEmailAccountIds:
                  bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
              }
            : {
                emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
              }),
        });
      },
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_DISCONNECT) && {
      key: 'disconnect_email_accounts',
      tooltip: 'Disconnect Email Accounts',
      ref: disconnectEmailAccountsRef,
      icon: <Unlink height={24} width={24} />,
      onClick: () => {
        setShowDisconnectEmailConfirmationPopup(true);
      },
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_DELETE) && {
      key: 'delete_email_accounts',
      tooltip: 'Delete Email Accounts',
      ref: deleteEmailAccountsRef,
      icon: <Trash height={24} width={24} />,
      onClick: () => {
        setShowDeleteEmailConfirmationPopup(true);
      },
    },
    // hasPermission(Permissions.EMAIL_ACCOUNT_DELETE) && {
    //   key: 'add_remove_tags',
    //   tooltip: 'Add & Remove Tags',
    //   ref: addRemoveTagRef,
    //   icon: <Tag height={24} width={24} />,
    //   onClick: () => {
    //     setShowTagsModal((prev) => !prev);
    //   },
    // },
  ].filter((opt) => opt);

  const handleDisconnectEmailAccounts = () => {
    sendBulkEmailAccountUpdateRequest({
      disconnectEmailAccounts: true,
      ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
        ? {
            allEmailsSelected: true,
            ignoreEmailAccountIds:
              bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
          }
        : {
            emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
          }),
    });
  };

  const handleDeleteEmailAccounts = () => {
    sendBulkEmailAccountUpdateRequest({
      deleteEmailAccounts: true,
      ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
        ? {
            allEmailsSelected: true,
            ignoreEmailAccountIds:
              bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
          }
        : {
            emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
          }),
    });
  };

  function getEmailAccountFilterStatus(status: any) {
    if (Array.isArray(status) && status.length === 0) return null;
    return status === 'Active'
      ? EmailAccountFilterStatus.Active
      : EmailAccountFilterStatus.Inactive;
  }

  const getFilteredEmailAccountFilter = (filters) => {
    const {
      status,
      owners,
      sequenceId,
      tagIds,
      emailServiceProvider,
      clientIds,
    } = filters;

    return {
      status: getEmailAccountFilterStatus(status),
      owners,
      sequenceIds: sequenceId || [],
      tagIds,
      emailServiceProvider,
      clientIds,
    };
  };

  const handleBulkAddTag = async (tags) => {
    const payload = {
      deSelectedEmailAccountIds:
        bulkSelectedEmailAccounts?.deSelectedEmailAccountIds,
      ids: tags.filter((tag) => tag?.id).map((tag) => tag.id),
      newTags: tags.filter((tag) => tag.isNew).map((tag) => tag.name),
      emailAccountFilter: getFilteredEmailAccountFilter(emailAccountsFilters),
    };

    await dispatch(emailAccountTagsBulkAssignRequest(payload));
    resetEmailAccountTagsList();
  };

  const handleAddTag = async (tags) => {
    if (bulkSelectedEmailAccounts?.isAllEmailAccountsSelected) {
      handleBulkAddTag(tags);
      return;
    }

    const payload = {
      emailAccountIds: selectedEmailAccounts.map((email) => email.id),
      ids: [],
      newTags: tags.map((tag) => tag.name),
    };

    await dispatch(emailAccountTagsAssignRequest(payload));
    resetSelectedEmailAccount();
    resetEmailAccountTagsList();
  };

  const handleBulkRemoveTag = async (tags) => {
    const payload = {
      deSelectedEmailAccountIds:
        bulkSelectedEmailAccounts?.deSelectedEmailAccountIds,
      tagIds: tags.filter((tag) => tag?.id).map((tag) => tag.id),
      emailAccountFilter: getFilteredEmailAccountFilter(emailAccountsFilters),
    };

    await dispatch(emailAccountTagsBulkUnassignRequest(payload));
    resetEmailAccountTagsList();
  };

  const handleRemoveTag = (tags) => {
    if (bulkSelectedEmailAccounts?.isAllEmailAccountsSelected) {
      handleBulkRemoveTag(tags);
      return;
    }

    const payload = {
      emailAccountIds: selectedEmailAccounts
        .filter((obj) => obj.id !== undefined)
        .map((email) => email.id),
      tagIds: tags.map((tag) => tag.id),
    };

    dispatch(emailAccountTagsUnassignRequest(payload));
    resetEmailAccountTagsList();
  };

  useEffect(() => {
    if (showModal) {
      if (emailAccountTagsList.length === 0) {
        sendGetEmailAccountsTagsRequest();
      }
    }
  }, [showModal]);

  useEffect(() => {
    executeOnRequestStatus({
      status: addTagsRequestStatus,
      onSuccess: () => {
        addRemoveTagRef?.current?.closeModal();
        toaster.success('Tags applied successfully', { theme: Theme.New });
        dispatch(getEmailAccountsRequest(emailAccountsFilters));
        dispatch(resetEmailAccountTagsList);
      },
    });
  }, [addTagsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: removeTagsRequestStatus,
      onSuccess: () => {
        addRemoveTagRef?.current?.closeModal();
        toaster.success('Tags removed successfully', { theme: Theme.New });
        dispatch(getEmailAccountsRequest(emailAccountsFilters));
        dispatch(resetEmailAccountTagsList);
      },
    });
  }, [removeTagsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: bulkAddTagsRequestStatus,
      onSuccess: () => {
        addRemoveTagRef?.current?.closeModal();
        toaster.success('Tags applied successfully', { theme: Theme.New });
        dispatch(getEmailAccountsRequest(emailAccountsFilters));
        dispatch(resetEmailAccountTagsList);
      },
    });
  }, [bulkAddTagsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: bulkRemoveTagsRequestStatus,
      onSuccess: () => {
        addRemoveTagRef?.current?.closeModal();
        toaster.success('Tags removed successfully', { theme: Theme.New });
        dispatch(getEmailAccountsRequest(emailAccountsFilters));
        dispatch(resetEmailAccountTagsList);
      },
    });
  }, [bulkRemoveTagsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: bulkEmailUpdateRequestStatus,
      onSuccess: () => {
        onHideBulkEmailDisconnectConfirmationModal();
        onHideBulkEmailDeleteConfirmationModal();
      },
      onFailed: () => {
        onHideBulkEmailDisconnectConfirmationModal();
        onHideBulkEmailDeleteConfirmationModal();
      },
    });
  }, [bulkEmailUpdateRequestStatus]);

  const actionClassName = classNames([
    'icon d-flex align-items-center justify-content-center',
    {
      disabled: !selectedEmailAccounts?.length,
    },
  ]);

  useEffect(() => {
    if (hasPermission(Permissions.CLIENT_READ) && isAgency()) {
      sendGetAgencyClientsDropdownRequest({});
    }
  }, []);

  const bulkUpdateLoading = getIsRequestPending(bulkEmailUpdateRequestStatus);

  const activeFilterCount = useMemo(
    () =>
      Object.values(emailAccountsFilters).reduce((count: number, filter) => {
        if (Array.isArray(filter) && filter.length > 0) {
          return count + 1; // Only count non-empty arrays
        }
        return count; // Skip other types and empty arrays
      }, 0),
    [emailAccountsFilters],
  );
  const isSyncConsentRequestPending = getIsRequestPending(
    syncConsentRequestStatus,
  );

  return (
    <>
      <div className="email-accounts-actions">
        <div className="d-flex align-items-center">
          <SearchInput
            searchValue={search}
            onSearch={onSearch}
            onClearSearch={onClearSearch}
            setSearchValue={setSearch}
            searchIconPlace={IconPlace.Left}
            searchIconFixed
            placeholder="Search & hit Enter"
          />
          {/* {hasPermission(Permissions.CLIENT_READ) &&
            isAgency() &&
            (isAssociatedClientRequestPending ? (
              <SkeletonLoading
                height={32}
                width={187}
                className="agency-client-email-accounts"
              />
            ) : (
              <AgencyClientFilters
                clientIds={emailAccountsFilters.clientIds || []}
                onClientIdsChange={(newClientIds) =>
                  updateEmailAccountsFilters({
                    ...emailAccountsFilters,
                    clientIds: newClientIds,
                  })
                }
                btnClassName="agency-client-email-accounts btn-white"
              />
            ))} */}

          <div className="icon-container position-relative">
            <div
              className="d-flex align-items-center justify-content-center icon"
              {...accessibleOnClick(openEmailAccountsFiltersModal)}
            >
              <Filter width={24} height={24} />
            </div>

            {activeFilterCount > 0 && (
              <span className="filter-count-badge">{activeFilterCount}</span>
            )}
          </div>

          <div className="separator-line" />
          <div className="d-flex align-items-center justify-content-center email-account-icons">
            {actions.map((action) => (
              <OverlayTooltip text={t(`labels.${action.key}`)} rootClose={true}>
                <div
                  ref={action.ref}
                  className={actionClassName}
                  {...accessibleOnClick(() => {
                    if (action.ref.current) {
                      action.ref.current.blur();
                    }
                    if (selectedEmailAccounts?.length) {
                      action.onClick();
                    }
                  })}
                >
                  {action.icon}
                </div>
              </OverlayTooltip>
            ))}

            {/* <AddRemoveTag
              handleAddTag={handleAddTag}
              handleRemoveTag={handleRemoveTag}
              selectedTags={selectedTags}
              selectedProspects={selectedEmailAccounts}
              isSubmitLoading={
                getIsRequestPending(addTagsRequestStatus) ||
                getIsRequestPending(removeTagsRequestStatus)
              }
              leadTags={emailAccountTagsList}
              isNotRenderDropdown={true}
            /> */}
            <AddRemoveTag
              ref={addRemoveTagRef}
              handleAddTag={handleAddTag}
              handleRemoveTag={handleRemoveTag}
              tags={emailAccountTagsList}
              selectedRecords={selectedEmailAccounts}
              disabled={!selectedEmailAccounts?.length}
              isShowOnlyTagIcon={true}
              isSubmitLoading={
                getIsRequestPending(addTagsRequestStatus) ||
                getIsRequestPending(removeTagsRequestStatus) ||
                getIsRequestPending(bulkAddTagsRequestStatus) ||
                getIsRequestPending(bulkRemoveTagsRequestStatus)
              }
              setShowModal={setShowModal}
              showModal={showModal}
              bulkSelectedEmailAccounts={bulkSelectedEmailAccounts}
            />
          </div>
        </div>
        <div className="d-flex gap-16">
          {hasPermission(Permissions.TI_SYNC_CONSENT) &&
            (isSyncConsentRequestPending ? (
              <Button
                variant="secondary"
                icon={Refresh}
                iconPlacement="left"
                className="header-btn"
                isLoading={true}
                loadingText="Loading..."
              >
                Loading...
              </Button>
            ) : (
              <>
                {syncConsentRequestResponse?.shConsent &&
                  !syncConsentRequestResponse.shConsent.consent && (
                    <Button
                      onClick={onTrulyInboxEmailSync}
                      variant="secondary"
                      icon={Refresh}
                      iconPlacement="left"
                      className="header-btn"
                      isLoading={
                        getIsRequestPending(getEmailAccountsRequestStatus) ||
                        getIsRequestPending(syncConsentRequestStatus)
                      }
                      loadingText="Sync Email with TrulyInbox"
                    >
                      Sync Email with TrulyInbox
                    </Button>
                  )}

                {syncConsentRequestResponse?.shConsent?.consent && (
                  <Button
                    onClick={onLoginWithTrulyInbox}
                    variant="secondary"
                    icon={Trulyinbox}
                    iconPlacement="left"
                    isLoading={
                      getIsRequestPending(getEmailAccountsRequestStatus) ||
                      getIsRequestPending(syncConsentRequestStatus)
                    }
                    className="header-btn login-with-trulyinbox"
                  >
                    Login to TrulyInbox
                  </Button>
                )}
              </>
            ))}

          {hasPermission(Permissions.EMAIL_ACCOUNT_CONNECT) && (
            <Button
              onClick={onAddEmailAccount}
              disabled={planCode === SubscriptionPlans.FREE}
              className="header-btn"
              isLoading={getIsRequestPending(
                getConnectedUsersAndEmailAccountsRequestStatus,
              )}
              loadingText="Add Email Account"
            >
              Add Email Account
            </Button>
          )}
        </div>
      </div>

      {isFiltersModalOpen && (
        <EmailAccountsFiltersModal
          show={isFiltersModalOpen}
          onClose={closeEmailAccountsFiltersModal}
          emailAccountsFilters={emailAccountsFilters}
          onFiltersChange={onFiltersChange}
        />
      )}

      {showDisconnectEmailConfirmationPopup && (
        <ConfirmationModalV3
          show={showDisconnectEmailConfirmationPopup}
          onClose={onHideBulkEmailDisconnectConfirmationModal}
          onSubmit={handleDisconnectEmailAccounts}
          title={t('labels.disconnect_email_accounts')}
          content={t('messages.disconnect_email_accounts_desc')}
          submitButtonText={t('labels.yes')}
          cancelButtonText={t('labels.cancel')}
          iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
          className="email-account-confirmation-popup disconnect"
          isSubmitLoading={bulkUpdateLoading}
        />
      )}
      {showDeleteEmailConfirmationPopup && (
        <ConfirmationModalV3
          show={showDeleteEmailConfirmationPopup}
          onClose={onHideBulkEmailDeleteConfirmationModal}
          onSubmit={handleDeleteEmailAccounts}
          title={t('labels.delete_email_accounts')}
          content={
            <span>
              {t('messages.delete_email_message_one')}{' '}
              {t('messages.delete_email_message_two')}
              {t('messages.delete_email_message_three')}
            </span>
          }
          submitButtonText={t('labels.yes')}
          cancelButtonText={t('labels.cancel')}
          iconType={ConfirmationModalIconType.TRASH_RED}
          className="email-account-confirmation-popup delete"
          isSubmitLoading={bulkUpdateLoading}
          showCloseIcon={false}
        />
      )}
    </>
  );
};

export default EmailAccountsActions;
