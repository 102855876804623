import { orderBy } from 'lodash';
import React, { memo, ReactNode, useCallback, useMemo } from 'react';
import ProspectTag from '../../atoms/prospect-tag';
import './prospect-tags-row.scss';

export type ProspectTagsRowProps = {
  /**
   * No of tags to display in a row. Other tags will be grouped together.
   */
  maxTags?: number;
  tags: any[];
  renderAddTag?: () => ReactNode;
  isColumnMode?: boolean;
  tagLength?: number;
  size?: number;
  isPosition?: boolean;
  emptyPlaceString?: string;
};

/**
 * Renders a Row of prospect tags.
 * Max no of tags in a row can be limited.
 * All the other tags will be grouped together.
 */
const ProspectTagsRow: React.FC<ProspectTagsRowProps> = ({
  tags,
  maxTags = tags?.length > 2 ? 1 : 2,
  renderAddTag,
  isColumnMode = false,
  tagLength = 10,
  size,
  isPosition = false,
  emptyPlaceString = '-' 
}) => {
  const sortedTags = useMemo(() => orderBy(tags, 'id', 'desc'), [tags]);

  let groupedTagNames: React.ReactNode = '';

  const [tagsToDisplay, tagsToGroup] = [
    sortedTags.slice(0, maxTags),
    sortedTags.slice(maxTags),
  ];

  groupedTagNames = useMemo(
    () => tagsToGroup.map(({ name, id }) => <div key={id}>{name}</div>),
    [tagsToGroup],
  );

  const renderTags = useCallback(() => {
    const row = tagsToDisplay.map(({ name, id }) => (
      <div key={id}>
        <ProspectTag
          name={name}
          tooltipText={name}
          isPopoverVisible={false}
          tagLength={tagLength}
        />
      </div>
    ));

    if (tagsToGroup.length) {
      row.push(
        <div key={tagsToGroup.length}>
          <ProspectTag
            name={`+${tagsToGroup.length}`}
            tooltipText={groupedTagNames}
            isPopoverVisible={true}
            tagLength={tagLength}
            position={isPosition ? 'bottom' : 'top'}
          />
        </div>,
      );
    }

    return row;
  }, [groupedTagNames, tagsToDisplay, tagsToGroup.length]);

  return (
    <div
      className={`${isColumnMode ? 'prospect-tag-column' : 'prospect-tag-row'}`}
    >
      {tagsToDisplay.length ? renderTags() : emptyPlaceString}
      {renderAddTag && renderAddTag()}
    </div>
  );
};

export default memo(ProspectTagsRow);
