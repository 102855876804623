import React, { useState } from 'react';
import { ChevronLeft } from '@saleshandy/icons';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';
import ImageIcon from '../../../../../../shared/components/images/image-icon';
import { Images } from '../../../../../../shared/app-constants';
import { IProps } from './type';
import { EmailAccountMethod } from '../../../../enums/email-account';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import { ProductTour } from '../../../../../../shared/enums/product-tour';
import { 
  isWhitelabelWithoutClientView, 
  getWhitelabelCompanyName, 
} from '../../../../../../shared/utils/user-details';

const EmailAccountService = ({
  id,
  logo,
  serviceName,
  serviceDetails,
  onClick,
}) => (
  <div
    id={id}
    className="email-account d-flex flex-column"
    {...accessibleOnClick(onClick)}
  >
    <ImageIcon src={logo} />
    <span className="semibold-1 blue-txt-16 mt-1 email-label">
      {serviceName}
    </span>
    <span className="regular-1 font-10 gray-txt-15 email-account__description">
      {serviceDetails}
    </span>
  </div>
);

const AddEmailAccountModal: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
  onSMTPAccountSelect,
  onCSVSelect,
  t,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const handleSMTPModal = () => {
    setIsModalOpen(false);
  };

  const handleFlag = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    onClose();
    setIsModalOpen(true);
  };
  const handleCSVSelect = () => {
    onCSVSelect();
    setIsModalOpen(true);
  };
  const handleSMTPSelect = () => {
    onSMTPAccountSelect();
    setIsModalOpen(true);
  };

  return (
    <>
      <Modal
        show={show}
        className={`add-email-account-modal ${
          !isModalOpen && 'add-smtp-account-modal'
        }`}
        titleContent={
          isModalOpen ? (
            'Select Account Type'
          ) : (
            <div>
              <ChevronLeft className="pointer" onClick={handleFlag} />
              <span className="icon-modal-text ml-3">
                Connect SMTP/IMAP accounts
              </span>
            </div>
          )
        }
        onClose={handleClose}
        showCloseIcon={true}
        hideFooter={true}
        backdrop="static"
        extraModalProps={{
          'aria-labelledby': 'contained-modal-title-vcenter',
          centered: true,
        }}
      >
        {isModalOpen ? (
          <>
            <div className="email-account-message regular-2">
              Connect your Email account from which you want to send email from{' '}
              {getWhitelabelCompanyName() || 'Saleshandy'}.
            </div>
            <div className="email-account-select-service">
              <span className="semibold-2">
                {t('messages.select_email_service')}
              </span>
            </div>
            <div
              id={ProductTour.SelectEmailService}
              className="email-account-method-wrap"
            >
              <EmailAccountService
                id="google"
                logo={Images.Google30x30}
                serviceName="Google"
                serviceDetails={t('messages.google')}
                onClick={() => onSubmit(EmailAccountMethod.Gmail)}
              />
              <EmailAccountService
                id="microsoft"
                logo={Images.Microsoft30x30}
                serviceName="Microsoft"
                serviceDetails={t('messages.microsoft')}
                onClick={() => onSubmit(EmailAccountMethod.Microsoft)}
              />
              <EmailAccountService
                id="smtp-imap"
                logo={Images.Server30x30}
                serviceName="SMTP/IMAP"
                serviceDetails={t('messages.smtp_imap')}
                onClick={handleSMTPModal}
              />
            </div>
            {isWhitelabelWithoutClientView() && (
              <div className="email-account-note regular-2 mt-4">
                {t('messages.dont_know_your_email_provider')}
                <a
                  href={process.env.REACT_APP_CHECK_EMAIL_SERVICE_PROVIDER}
                  className="ml-1 link"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('labels.click_here')}.
                </a>
              </div>
            )}
            <div className="email-account-note regular-2 mt-3">
              {t('messages.email_account_connect_note')}
            </div>
          </>
        ) : (
          <>
            <div className="email-account-message  regular-2">
              {t('messages.SMTP_account_select_message')}
            </div>

            <div className="email-account-method-wrap">
              <EmailAccountService
                id="connect-single"
                logo={Images.Server30x30}
                serviceName="Connect Single account"
                serviceDetails="Connect single email account via  SMTP"
                onClick={handleSMTPSelect}
              />

              <EmailAccountService
                id="bulk-import"
                logo={Images.CSV}
                serviceName="Bulk Import Via CSV"
                serviceDetails="Import & connect multiple email accounts at once via CSV"
                onClick={handleCSVSelect}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default AddEmailAccountModal;
