import React from 'react';
import Avatar from '../../initials-avatar';

export function AvatarEmail({ userProfile }) {
  return (
    <li className="my-1">
      <div className="d-flex profile-border-bottom px-2 py-2">
        <div className="text-left">
          <Avatar
            firstName={userProfile.firstName}
            lastName={userProfile.lastName}
            className='dropdown-header-avatar'
          />
        </div>
        <div className="text-left dropdown-title-text ml-2">
          <p className="profile-list-font dropdown-title-fullname" >
            {userProfile.firstName} {userProfile.lastName}
          </p>
          <p className="profile-email-font">{userProfile.email}</p>
        </div>
      </div>
    </li>
  );
}
