import React, { useRef, useImperativeHandle } from 'react';

import Table from '../../../../../../shared/design-system/components/organisms/table';
import { PaginationShowHide } from '../../../../../../shared/design-system/components/organisms/table/types';
import {
  ProspectFilters,
  ProspectPagination,
  ProspectParams,
  SelectAllContacts,
} from '../../../../types';
import ContactNameField from '../../../../../../shared/design-system/components/molecules/contact-name-field';
import { SortOrder } from '../../../../../sequence/enums';

type IProps = {
  changePagination: (params: ProspectPagination) => void;
  getProspectRequest: (params: ProspectParams) => void;
  prospectCount: number;
  prospectPaginationData: ProspectPagination;
  search: string;
  getProspectRequestStatus;
  selectAllContact: (rows: any, status: any) => void;
  selectSingleContact: (row, status) => void;
  resetSelected: boolean;
  column: any;
  data: any;
  selectedProspects?: any[];
  showNewPagination?: boolean;
  tableRef?: any;
  isAnyFilterApplied?: boolean;
  isProspectSelectBannerShow?: boolean;
  selectedAllProspects?: SelectAllContacts;
  pageHeaderBannerMessage?: string;
  isLoading?: boolean;
  viewShowProspectDetailsModal?: (id: number) => void;
  prospectFilters?: ProspectFilters;
  isRefreshClicked?: boolean;
  setOnRefresh?: (refresh: boolean) => void;
  verified?: boolean;
};

const ProspectListTable: React.FC<IProps> = ({
  changePagination,
  getProspectRequest,
  prospectCount,
  prospectPaginationData,
  search,
  selectAllContact,
  selectSingleContact,
  resetSelected,
  column,
  data,
  showNewPagination = true,
  tableRef,
  selectedAllProspects,
  isLoading,
  viewShowProspectDetailsModal,
  prospectFilters,
  isRefreshClicked,
  setOnRefresh,
}) => {
  const ref = useRef<any>(null);

  const paginationChange = ({ page, limit }) => {
    changePagination({ pageNum: page - 1, pageSize: limit });
    const query: ProspectParams = {
      pageNum: page - 1,
      pageSize: limit,
    };
    if (prospectFilters && prospectFilters.sortBy) {
      query.sortBy = prospectFilters.sortBy;
      query.sortOrder = prospectFilters.sortOrder || SortOrder.Asc;
    }
    if (search) {
      query.search = search;
    }
    getProspectRequest(query);
  };

  const allSelected = (rows, isSelect: boolean) => {
    selectAllContact(rows, isSelect);
  };

  const singleSelected = (row, isSelect: boolean) => {
    selectSingleContact(row, isSelect);
  };

  // // Prospect Table Classes
  // const prospectTableClass = classNames([
  //   {
  //     'sm-table':
  //       (showNewPagination &&
  //         isProspectSelectBannerShow &&
  //         !isAnyFilterApplied) ||
  //       pageHeaderBannerMessage,
  //   },
  //   {
  //     'md-table':
  //       showNewPagination &&
  //       selectedProspects.length === 0 &&
  //       !isProspectSelectBannerShow &&
  //       !isAnyFilterApplied &&
  //       !pageHeaderBannerMessage,
  //   },
  //   {
  //     'smm-table':
  //       showNewPagination && isAnyFilterApplied && !isProspectSelectBannerShow,
  //   },
  //   {
  //     'mdd-table':
  //       showNewPagination && isAnyFilterApplied && isProspectSelectBannerShow,
  //   },
  // ]);

  useImperativeHandle(tableRef, () => ({
    resetSelectedRows() {
      ref.current?.resetSelectedRows();
    },
    selectAllCurrentPageRows() {
      ref.current?.selectAllCurrentPageRows();
    },
  }));

  const columnsWithHandleEngagementOnClick = column.map((col) => {
    if (col.dataField === 'fields' && showNewPagination) {
      return {
        ...col,
        component: (cell, row) => (
          <ContactNameField
            cell={cell}
            status={row.status}
            onClick={() => viewShowProspectDetailsModal(row.id)}
            row={row}
          />
        ),
      };
    }

    return col;
  });

  return (
    <Table
      columns={columnsWithHandleEngagementOnClick}
      data={data}
      onPaginationOptionsChange={paginationChange}
      paginationOptions={{
        options: {
          limit: prospectPaginationData.pageSize,
          page: prospectPaginationData.pageNum + 1,
          totalElements: prospectCount,
        },
      }}
      onRowSelect={(row, isSelect) => singleSelected(row, isSelect)}
      onRowSelectAll={(rows, isSelect) => allSelected(rows, isSelect)}
      resetSelected={resetSelected}
      pagination={PaginationShowHide.SHOW}
      isNewPagination={showNewPagination}
      headerWrapperClasses="prospect-table-header"
      bodyWrapperClasses="prospect-body-wrapper"
      // eslint-disable-next-line no-return-assign
      tableRef={(refValue: any) => (ref.current = refValue)}
      deselectedRows={selectedAllProspects?.deSelectedContactIds}
      isLoading={isLoading}
      isRefreshClicked={isRefreshClicked}
      setOnRefresh={setOnRefresh}
    />
  );
};

export default ProspectListTable;
